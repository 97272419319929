import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from 'ThemeContext';

import {
  Button,
  Buttonbox,
  Description,
  Error,
  Errorbox,
  ErrorIcon,
  Fieldbox,
  Formbox,
  Input,
  InputBox,
  InputIcon,
  InputSign,
  KnowYourPasswordBox,
  Link,
  Panel,
  Title,
  Rule,
  RuleIcon,
  RuleText
} from './ChangePasswordPanel.style';

import { useState } from 'react';

const PASSWORD_RULES = [
  {
    id: 'minLength',
    text: 'Password must be at least 8 characters long'
  },
  {
    id: 'atLeastOneUpperLetter',
    text: 'Password must contain at least one uppercase letter'
  },
  {
    id: 'atLeastOneLowerLetter',
    text: 'Password must contain at least one lowercase letter'
  },
  {
    id: 'atLeastOneDigit',
    text: 'Password must contain at least one digit'
  },
  {
    id: 'atLeastOneSpecialCharacter',
    text: 'Password must contain at least one special character'
  },
  {
    id: 'notMatch',
    text: 'Passwords match'
  }
];

const ChangePasswordPanel = ({ onSubmit = () => {} }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const validate = values => {
    const errors = {};

    if (values.password == null || values.password.length < 8) {
      errors.minLength = false;
    }

    if (values.password !== values.confirmpassword) {
      errors.notMatch = false;
    }
    if (!/[A-Z]/.test(values.password)) {
      errors.atLeastOneUpperLetter = false;
    }
    if (!/[a-z]/.test(values.password)) {
      errors.atLeastOneLowerLetter = false;
    }
    if (!/\d/.test(values.password)) {
      errors.atLeastOneDigit = false;
    }

    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(values.password)) {
      errors.atLeastOneSpecialCharacter = false;
    }

    return errors;
  };

  return (
    <Panel>
      <Formbox>
        <Title theme={theme.title}>
          <FormattedMessage id='CHANAGE_PASSWORD_PANEL.TITLE' />
        </Title>
        <Description>
          <FormattedMessage id='CHANAGE_PASSWORD_PANEL.DESCRIPTION' />
        </Description>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            submitting,
            submitError,
            pristine,
            invalid,
            errors,
            values
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Errorbox>
                  {submitError && (
                    <Error theme={theme.error}>
                      <ErrorIcon className='material-icons'>
                        info_outline
                      </ErrorIcon>{' '}
                      {submitError}
                    </Error>
                  )}
                </Errorbox>
                <Fieldbox>
                  <Field name='password'>
                    {({ input, meta }) => (
                      <InputBox theme={theme.inputBox}>
                        <InputIcon
                          theme={theme.inputIcon}
                          className='material-icons'
                          data-filled={input.value ? true : undefined}
                        >
                          lock_outline
                        </InputIcon>
                        <Input
                          {...input}
                          theme={theme.input}
                          type={hidePassword ? 'password' : 'text'}
                          placeholder={intl.formatMessage({
                            id: `CHANAGE_PASSWORD_PANEL.PASSWORD`
                          })}
                          autoComplete='on'
                          data-invalid={
                            meta.touched && meta.error ? true : undefined
                          }
                          data-valid={
                            meta.touched && !meta.error ? true : undefined
                          }
                        />
                        <InputSign
                          theme={theme.inputsign}
                          className='material-icons-outlined'
                          onClick={() => {
                            setHidePassword(!hidePassword);
                          }}
                        >
                          {hidePassword ? 'visibility_off' : 'visibility'}
                        </InputSign>
                      </InputBox>
                    )}
                  </Field>
                  {PASSWORD_RULES.map(rule => (
                    <Rule key={rule.id}>
                      <RuleIcon
                        className='material-icons-outlined'
                        theme={theme.rule.checkbox}
                        active={
                          !pristine &&
                          values.password != null &&
                          errors[rule.id] !== false
                        }
                      >
                        check_box
                      </RuleIcon>
                      <RuleText>{rule.text}</RuleText>
                    </Rule>
                  ))}
                  <Field name='confirmpassword'>
                    {({ input, meta }) => (
                      <InputBox theme={theme.inputBox}>
                        <InputIcon
                          theme={theme.inputIcon}
                          className='material-icons'
                          data-filled={input.value ? true : undefined}
                        >
                          lock_outline
                        </InputIcon>
                        <Input
                          {...input}
                          theme={theme.input}
                          type={hideConfirmPassword ? 'password' : 'text'}
                          placeholder={intl.formatMessage({
                            id: `CHANAGE_PASSWORD_PANEL.CONFIRM_PASSWORD`
                          })}
                          autoComplete='on'
                          data-invalid={
                            meta.touched && meta.error ? true : undefined
                          }
                          data-valid={
                            meta.touched && !meta.error ? true : undefined
                          }
                        />
                        <InputSign
                          theme={theme.inputsign}
                          className='material-icons-outlined'
                          onClick={() => {
                            setHideConfirmPassword(!hideConfirmPassword);
                          }}
                        >
                          {hideConfirmPassword
                            ? 'visibility_off'
                            : 'visibility'}
                        </InputSign>
                      </InputBox>
                    )}
                  </Field>
                </Fieldbox>
                <Buttonbox>
                  <Button
                    theme={theme.button}
                    disabled={submitting || pristine || invalid}
                  >
                    {submitting ? (
                      <FormattedMessage id='CHANAGE_PASSWORD_PANEL.PLEASE_WAIT' />
                    ) : (
                      <FormattedMessage id='CHANAGE_PASSWORD_PANEL.CONFIRM_BTN' />
                    )}
                  </Button>
                </Buttonbox>
              </form>
            );
          }}
        />
      </Formbox>
      <KnowYourPasswordBox>
        <FormattedMessage id='FORGOT_PASSWORD.KNOW_YOUR_PASSWORD' />{' '}
        <Link to='/sign-in' theme={theme.forgotPassword.link}>
          Sign in
        </Link>
      </KnowYourPasswordBox>
    </Panel>
  );
};

ChangePasswordPanel.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ChangePasswordPanel;
