import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from 'ThemeContext';

import {
  Button,
  Buttonbox,
  Description,
  Error,
  Errorbox,
  ErrorIcon,
  Fieldbox,
  Formbox,
  Input,
  InputBox,
  InputIcon,
  Panel,
  Title,
  KnowYourPasswordBox,
  Link
} from './ForgotPasswordPanel.style';

const ForgotPasswordPanel = ({ onSubmit = () => {} }) => {
  const intl = useIntl();
  const theme = useTheme();

  const validate = values => {
    //valide if values.email is email

    if (
      !values.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      return { email: 'Invalid email address' };
    }
  };

  return (
    <Panel>
      <Formbox>
        <Title theme={theme.title}>
          <FormattedMessage id='FORGOT_PASSWORD.TITLE' />
        </Title>
        <Description>
          <FormattedMessage id='FORGOT_PASSWORD.DESCRIPTION' />
        </Description>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, submitError, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Errorbox>
                {submitError && (
                  <Error theme={theme.error}>
                    <ErrorIcon className='material-icons'>
                      info_outline
                    </ErrorIcon>{' '}
                    {submitError}
                  </Error>
                )}
              </Errorbox>
              <Fieldbox>
                <Field name='email'>
                  {({ input, meta }) => (
                    <InputBox theme={theme.inputBox}>
                      <InputIcon
                        theme={theme.inputIcon}
                        className='material-icons'
                        data-filled={input.value ? true : undefined}
                      >
                        mail_outline
                      </InputIcon>
                      <Input
                        {...input}
                        theme={theme.input}
                        placeholder={intl.formatMessage({
                          id: `FORGOT_PASSWORD.EMAIL`
                        })}
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                    </InputBox>
                  )}
                </Field>
              </Fieldbox>
              <Buttonbox>
                <Button theme={theme.button} disabled={submitting || pristine}>
                  {submitting ? (
                    <FormattedMessage id='FORGOT_PASSWORD.PLEASE_WAIT' />
                  ) : (
                    <FormattedMessage id='FORGOT_PASSWORD.CONFIRM_BTN' />
                  )}
                </Button>
              </Buttonbox>
            </form>
          )}
        />
      </Formbox>
      <KnowYourPasswordBox>
        <FormattedMessage id='FORGOT_PASSWORD.KNOW_YOUR_PASSWORD' />{' '}
        <Link to='/sign-in' theme={theme.forgotPassword.link}>
          Sign in
        </Link>
      </KnowYourPasswordBox>
    </Panel>
  );
};

ForgotPasswordPanel.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ForgotPasswordPanel;
