import React, { useState } from 'react';
import useWindowSize from '@rehooks/window-size';

import IMG_BMS_ICON from 'assets/Images/SIFD_Orange_icon_BMS.svg';

import Helmet from 'components/Helmet';
import Logo from 'components/Logo';
import ForgotPasswordPanel from 'components/ForgotPasswordPanel';
import SwitchLanguage from 'components/SwitchLanguage';

import {
  Section,
  Wrapper,
  Content,
  Navigation,
  Imagebox,
  Image
} from './ForgotPasswordPage.style';
import { Breakpoints } from 'assets/Variables.style';
import { requestPasswordResetEmail } from 'requestAPI';
import MessagePanel from 'components/MessagePanel';

const STATUSES = {
  IDLE: 'IDLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const ForgotPasswordPage = () => {
  let windowSize = useWindowSize();
  const [status, setStatus] = useState(STATUSES.IDLE);

  const handleSubmit = async values => {
    const res = await requestPasswordResetEmail(values.email);

    if (res.error) {
      setStatus(STATUSES.ERROR);
      return;
    }

    setStatus(STATUSES.SUCCESS);
  };

  return (
    <>
      <Helmet title={'LANG_PAGE_TITLE.FORGOT_PASSWORD'} />
      <Section>
        <Wrapper>
          <Content>
            <Logo />
            {status === STATUSES.IDLE ? (
              <ForgotPasswordPanel onSubmit={handleSubmit} />
            ) : null}
            {status === STATUSES.SUCCESS ? (
              <MessagePanel
                title='E-mail has been sent'
                description='An email with instructions on how to reset your password has been sent to your inbox. If you do not receive it within a few minutes, please check that the provided email address matches the one used during account registration, and try again or contact us for assistance.'
              />
            ) : null}
            {status === STATUSES.ERROR ? (
              <MessagePanel
                title='Opss...'
                description='An error occurred while trying to send the email with password reset instructions.'
              />
            ) : null}
          </Content>
        </Wrapper>
        <Wrapper hideMedium>
          <Imagebox>
            <Image src={IMG_BMS_ICON} alt='BMS' />
          </Imagebox>
        </Wrapper>
      </Section>
    </>
  );
};

export default ForgotPasswordPage;
