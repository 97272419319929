import { useTheme } from 'ThemeContext';
import {
  Button,
  Description,
  Panel,
  Title,
  Buttonbox
} from './MessagePanel.style';

const MessagePanel = ({ title, description }) => {
  const theme = useTheme();
  return (
    <Panel>
      <Title theme={theme.title}>{title}</Title>
      <Description>{description}</Description>
      <Buttonbox>
        <Button to='/sign-in' theme={theme.button}>
          LOGIN
        </Button>
      </Buttonbox>
    </Panel>
  );
};

export default MessagePanel;
