import SignInPage from 'views/SignInPage';
import SignUpPage from 'views/SignUpPage';
import ForgotPasswordPage from 'views/ForgotPasswordPage';
import ChangePasswordPage from 'views/ChangePasswordPage';

const Routes = [
  {
    path: '/sign-up',
    name: 'SIGN-UP-PAGE',
    component: SignUpPage,
    exact: true
  },
  {
    path: '/forgot-password',
    name: 'FORGOT-PASSWORD-PAGE',
    component: ForgotPasswordPage,
    exact: true
  },
  {
    path: '/change-password',
    name: 'CHANGE_PASSWORD_PAGE',
    component: ChangePasswordPage,
    exact: false
  },
  {
    path: '/sign-in',
    name: 'SIGN-IN-PAGE',
    component: SignInPage,
    exact: true
  }
];

export default Routes;
