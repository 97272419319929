import styled from 'styled-components';

import {
  Panel as commonPanel,
  Title as commonTitle,
  Formbox as commonFormbox,
  Fieldbox as commonFieldbox,
  Buttonbox as commonButtonbox
} from 'assets/Common/SignPanel.style';

import {
  InputBox as commonInputBox,
  Input as commonInput,
  InputIcon as commonInputIcon,
  InputSign as commonInputSign
} from 'assets/Common/Inputbox.style';
import { Button as commonButton } from 'assets/Common/Button.style';

import {
  Errorbox as commonErrorbox,
  Error as commonError,
  ErrorIcon as commonErrorIcon
} from 'assets/Common/Error.style';

import { Link as commonLink } from 'assets/Common/Link.style';

import { Link as RouterLink } from 'react-router-dom';

export const Panel = styled.div`
  ${commonPanel}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const Errorbox = styled.div`
  ${commonErrorbox}
`;

export const Error = styled.div`
  ${commonError}
`;

export const ErrorIcon = styled.i`
  ${commonErrorIcon}
`;

export const Formbox = styled.div`
  ${commonFormbox}
`;

export const Fieldbox = styled.div`
  ${commonFieldbox}
`;

export const InputBox = styled.div`
  ${commonInputBox}
`;

export const Input = styled.input`
  ${commonInput}
`;

export const InputIcon = styled.i`
  ${commonInputIcon}
`;

export const InputSign = styled.i`
  ${commonInputSign}
`;

export const Buttonbox = styled.div`
  ${commonButtonbox}
`;

export const Button = styled.button`
  ${commonButton}
`;

export const Description = styled.div`
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const KnowYourPasswordBox = styled.div`
  font-size: 19px;
  text-align: center;
  margin-top: 30px;
`;

export const Link = styled(RouterLink)`
  ${commonLink}
  font-weight: 500;
`;

export const Rule = styled.div`
  display: flex;
  gap: 10px;
`;

export const RuleIcon = styled.i`
  color: ${props => (props.active ? props.theme.active : props.theme.inactive)};
`;

export const RuleText = styled.div`
  font-size: 14px;
`;
