import styled from 'styled-components';

import {
  Panel as commonPanel,
  Title as commonTitle,
  Buttonbox as commonButtonbox
} from 'assets/Common/SignPanel.style';

import { Link } from 'react-router-dom';
import { Button as commonButton } from 'assets/Common/Button.style';

export const Panel = styled.div`
  ${commonPanel}
`;

export const Title = styled.h1`
  ${commonTitle}
  margin-bottom: 60px;
`;

export const Buttonbox = styled.div`
  ${commonButtonbox}
`;

export const Button = styled(Link)`
  ${commonButton}
`;

export const Description = styled.div`
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`;
